@import '../../styles/variables';
@import '../../styles/mixins';

.answer-card-container {
  position: fixed;
  bottom: 82px;
  width: 100%;
  left: 0;
  background: $color-almost-white;
  display: flex;
  justify-content: center;
  height: 102px;

  .answer-cards-wrapper {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    bottom: 16px;
    width: 100%;
  }

  .answer-card-content {
    overflow-x: auto;
    padding-top: 28px;

    .answer-card {
      display: flex;
      min-height: 147px;
      max-height: 213px;
      max-width: 134px;
      min-width: 88px;
      background: $color-white;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: $shadow-small;
      position: relative;
      pointer-events: all;
      @include vertical-transform(0px);
      @include custom-transition(all,0.2, ease-in-out);
      -webkit-tap-highlight-color: transparent;

      img {
        width: 100%;
        align-self: flex-end;
      }

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.active{
        @include vertical-transform(-20px);

         &:before{
           content: "";
           position: absolute;
           width: 100%;
           height: 100%;
           top: 0;
           left: 0;
           border-radius: 8px;
           pointer-events: none;
           @include custom-transition(all,0.2, ease-in-out);
         }

         &.correct::before,
         &.wrong::before{
          border: 4px solid;
         }
         
         &.correct::before{
          border-color: $color-green;
         }

         &.wrong::before{
           border-color: $color-red;
         }
      }
    }
  }
}

@media (min-width: 319px) {

  .answer-card:first-of-type {
    margin-left: 5%;
  }

  .answer-card:last-of-type {
    margin-right: 5%;
  }
}

@media (min-width: $tablet-min-width) {

  .answer-card:first-of-type {
    margin-left: 2.5%;
  }

  .answer-card:last-of-type {
    margin-right: 2.5%;
  }
}

@media (min-width: $desktop-min-width) {

  .answer-card:first-of-type {
    margin-left: 8%;
  }

  .answer-card:last-of-type {
    margin-right: 8%;
  }
}