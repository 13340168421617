@import '../../styles/variables';
@import '../../styles/mixins';

.badge {
  @include body-small-styles();
  display: inline-block;
  padding: 8px 16px;
  font-weight: 800;
  border-radius: 16px;
  text-transform: uppercase;

  &.badge-label {
    background-color: $color-almost-white;
  }

  &.badge-success {
    background-color: $color-green;
  }

  &.badge-error {
    background-color: $color-red;
  }
}