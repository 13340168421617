@import './mixins';

h1 {
  @include h1-styles;
}

h2 {
  @include h2-styles;
}

h3 {
  @include h3-styles;
}

h4 {
  @include h4-styles;
}

body,
p {
  @include body-styles;
}

.small-text {
  @include body-small-styles;
}
