@import './styles/typography';
@import './styles/bootstrap-grid';

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 320px;

  &.bottom-action-bar-rendered {
    padding-bottom: 82px;
  }

  &.header-rendered {
    padding-top: 56px;
  }
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}