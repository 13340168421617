@import '../../styles/variables';

.bottom-action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $color-white;
  box-shadow: $shadow-big;
  width: 100%;
  padding: 16px 0;

  .bottom-action-bar-content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    button:not(:last-child),
    a:not(:last-child){
        margin-right: 8px;
    }
  }
}