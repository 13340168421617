// Colors
$color-black: #000000;
$color-white: #ffffff;
$color-almost-white: #ebebeb;
$color-grey: #c1c1c1;
$color-red: #ff6868;
$color-yellow: #fff384;
$color-green: #7effb5;
$color-blue: #7ed1ff;

// Shadows
$shadow-big: 0px 0px 10px rgba(0, 0, 0, 0.25);
$shadow-small: 0px 0px 10px rgba(0, 0, 0, 0.1);

// Fonts
$font-open-sans: 'Open Sans', sans-serif;

// Responsive points
$desktop-min-width: 1024px;
$tablet-max-width: 1023px;
$tablet-min-width: 640px;
$mobile-max-width: 639px;
$mobile-min-width: 320px;

// Gutters
$gutter-positive: 8px;
$gutter-negative: -8px;