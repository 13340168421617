@import '../../styles/variables';
@import '../../styles/mixins';

.timer-progress-wrapper {
  position: relative;
  display: inline-flex;

  .progress-circle-wrapper {
    $default-size: 22px;
    position: absolute;
    top: 9px;
    left: 5px;
    display: inline-flex;
    margin: 0px;
    height: $default-size;
    width: $default-size;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .progress-circle {
      height: 100%;
      width: 100%;
      clip: rect(0, $default-size, $default-size, ($default-size / 2));
      left: 0;
      position: absolute;
      top: 0;

      &.progress-circle-double {
        clip: rect(auto, auto, auto, auto);

        .right-circle {
          transform: rotate(180deg);
        }
      }

      .half-circle {
        height: 100%;
        width: 100%;
        background-color: $color-black;
        border-radius: 50%;
        clip: rect(0, $default-size / 2, $default-size, 0);
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }
}