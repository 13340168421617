@import '../../styles/variables';
@import '../../styles/mixins';

.question-cards-container {
  display: flex;
  justify-content: center;
  height: 450px;
  width: 281px;
  position: relative;

  .question-card {
    position: absolute;
    width: 281px;
    height: 450px;
    border-radius: 16px;
    box-shadow: $shadow-big;
    animation-duration: 1000ms;
    animation-iteration-count: initial;
    animation-timing-function: ease-in-out;
    background: $color-white;
    opacity: 0;
    padding: 34px;

    &.rotate-out {
      transform-origin: 15% 100%;
      animation-name: rotate-out;
      transform: rotate(90deg);
      opacity: 0;
    }

    &.rotate-in {
      transform-origin: 30% 100%;
      animation-name: rotate-in;
      transform: rotate(0deg);
      opacity: 1;
    }

    .question-card-title {
      text-align: center;
      margin-bottom: 21px;
    }

    .question-card-quest {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 204px;

      h3 {
        display: -webkit-box;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }

    .question-card-actions-container {
      height: 72px;
      margin-top: 21px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .question-card-points {
        margin-top: 8px;
      }
    }
  }
}

@media (max-width: $mobile-max-width) {
  .question-cards-container {
    width: 237px;
    height: 380px;
    .question-card {
      width: 237px;
      height: 380px;
      padding: 21px;

      .question-card-quest {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;

        h3 {
          font-size: 22px;
          line-height: 30px;
          -webkit-line-clamp: 5;
        }
      }

      .question-card-actions-container {
        margin-top: 40px;
      }

      .question-card-title {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}

@media (max-width: 380px) {
  .question-cards-container {
    width: 169px;
    height: 270px;
    .question-card {
      width: 169px;
      height: 270px;
      padding: 16px 8px;

      .question-card-title {
        margin-bottom: 18px;
      }

      .question-card-quest {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;

        h3 {
          font-size: 15px;
          line-height: 20px;
          -webkit-line-clamp: 4;
        }
      }

      .question-card-actions-container {
        margin-top: 14px !important;
      }

      .question-card-title {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }
}


@media (max-height: 685px){
  .question-cards-container {
    .question-card {
      .question-card-actions-container {
        margin-top: 21px;
      }
    }
  }
}

@keyframes rotate-in {
  from {
    transform: rotate(-45deg);
    opacity: 0;
  }

  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}

@keyframes rotate-out {
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: rotate(90deg);
    opacity: 0;
  }
}