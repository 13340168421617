@import '../../styles/variables';
@import '../../styles/mixins';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: $shadow-big;
  background: $color-white;
  z-index: 99;
  
  .header-content {
    display: flex;
    align-items: center;
    height: 56px;
    justify-content: space-between;
    @include body-styles();
    font-weight: bold;

    & > div{
      display: flex;
      align-items: center;

      &.header-center-container{
        padding: 0 16;
      }

      a{
        color: inherit;
        text-decoration: none;
      }
    }
  }
}