@import '../../styles/variables';

.game-container{
  height: calc(100vh - 360px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  overflow: hidden;
}

@media (max-width: $mobile-max-width) {
  .game-container{
    height: calc(100vh - 340px);
    min-height: 416px;
  }
}

@media (max-width: 380px) {
  .game-container{
    height: calc(100vh - 320px);
    min-height: 302px;
  }
}

// @media (max-height: 737px) {
//     .game-container{
//       height: calc(100vh - 270px);
//     }
//   }