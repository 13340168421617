@import './variables';

@mixin h1-styles {
  margin: 0;
  font-family: $font-open-sans;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 52px;
  text-transform: uppercase;
  color: $color-black;
}

@mixin h2-styles {
  margin: 0;
  font-family: $font-open-sans;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  text-transform: uppercase;
  color: $color-black;
}

@mixin h3-styles {
  margin: 0;
  font-family: $font-open-sans;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  color: $color-black;
}

@mixin h4-styles {
  margin: 0;
  font-family: $font-open-sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $color-black;
}

@mixin body-styles {
  margin: 0;
  font-family: $font-open-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $color-black;
}

@mixin body-small-styles {
  margin: 0;
  font-family: $font-open-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $color-black;
}

@mixin reset-button-styles {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
}

@mixin vertical-center-transform() {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@mixin horizontal-center-transform() {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

@mixin full-center-transform() {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

@mixin custom-transition($param, $seconds, $type) {
  transition: #{$param} #{$seconds}s $type;
  -webkit-transition: #{$param} #{$seconds}s $type;
  -moz-transition: #{$param} #{$seconds}s $type;
  -o-transition: #{$param} #{$seconds}s $type;
}

@mixin vertical-transform($value) {
  transform: translateY($value);
  -webkit-transform: translateY($value);
  -moz-transform: translateY($value);
  -ms-transform: translateY($value);
}
