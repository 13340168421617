@import '../../styles/variables';

.answer-reaction {
  position: relative;
  pointer-events: none;

  img {
    position: absolute;
    opacity: 0;
    bottom: 0;
    animation: float cubic-bezier(0.22, 0.61, 0.36, 1) 1;
  }

  @keyframes float {
    0% {
      display: none;
      opacity: 1;
      transform: translateY(0px);
    }

    50% {
      opacity: 0.65;
    }

    100% {
      opacity: 0;
      transform: translateY(-400px);
    }
  }
}