@import '../../styles/variables';
@import '../../styles/mixins';

.btn {
  @include reset-button-styles();
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $font-open-sans;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-transform: uppercase;
  border-radius: 25px;
  padding: 9px 28px;
  min-width: 182px;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &.btn-primary {
    color: $color-white;
    background: $color-black;
  }

  &.btn-small {
    font-size: 12px;
    line-height: 16px;
    border-radius: 16px;
    padding: 8px 28px;
    min-width: 107px;
  }


  &.btn-icon {
    padding: 0;
    min-width: unset;
    line-height: unset;
    border-radius: unset;
    background: unset;
    color: $color-black
  }

  &.btn-cta{
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;
    flex-direction: column;
    padding: 1px 6px;
    min-width: 60px;
    font-weight: 400;
    text-transform: unset;
    background: $color-black;
    color: $color-white;
    min-height: 50px;

    &.btn-cta-danger{
      background: $color-red;
      &.active{
        background: $color-black;
      }
    }

    &.btn-cta-white{
      background: $color-white;
      color: $color-black;
      &.active{
        background: $color-black;
        color:$color-white;
      }
    }

  }

  &:disabled {
    background: $color-grey !important;
    cursor: not-allowed;
  }
}