.final-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 80px !important;
  height: 100vh;

  .top-box {
    display: flex;
    align-items: center;
    flex-direction: column;

    h3 {
      margin-bottom: 15px;
    }

    .badge {
      margin-bottom: 10px;
    }
  }
}